import i18n from '@/shared/plugins/vue-i18n';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

const enumLabel = (name, value) => i18n.t(`ACCOUNTS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

export default class RoleField {

    static relationToOne(name, label, filterParams) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ApiService.query('roles', {
                        params: {
                            query: `scope eq 'team'`,
                            fields: 'name',
                        },
                    })
                        .then(({ data }) => {
                            resolve(data.data);
                        });
                });
            },
            (record) => {
                return {
                    value: record.name,
                    text: enumLabel('roles', record.name),
                };
            },
            {},
            filterParams,
        );
    }
};
