<template>
    <div v-can:create="'accounts'">
        <st-button
            size="large"
            variant="secondary"
            :callback="addAccount"
        >
            <i class="fa fa-plus"></i>
            {{ $t('ACCOUNTS.BUTTONS.ADD_ACCOUNT') }}
        </st-button>

        <add-account-modal ref="add-account-modal"/>
    </div>
</template>

<script>
import StButton from '../../../shared/components/StButton.vue';
import AddAccountModal from '@/modules/accounts/components/AddAccountModal.vue';

export default {
  components: { StButton, AddAccountModal },
    name: 'AccountsToolbar',
    methods: {
        addAccount() {
            this.$refs['add-account-modal'].show();
        },
    },
}
</script>