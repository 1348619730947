import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import RoleField from './role-field';
import CreatedByNameField from './created-by-name-field';

const field = (name) => i18n.t(`ACCOUNTS.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`ACCOUNTS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const label = (name) => i18n.t(`ACCOUNTS.LABELS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`ACCOUNTS.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    user_name: new StringField(
        'user_name',
        field('user_name'),
        {},
        {
            placeholder: placeholder('search_name'),
            filter_label: label('user'),
            primary: false,
        },
    ),
    email: new StringField(
        'email',
        field('email'),
    ),
    role: RoleField.relationToOne(
        'role',
        field('role'),
        {
            placeholder: placeholder('rol'),
        },
    ),
    status: new EnumField(
        'status',
        field('status'),
        [
            {
                value: 'pending',
                name: 'pending',
                text: enumLabel('status', 'pending'),
                type: 'primary',
            },
            {
                value: 'active',
                name: 'active',
                text: enumLabel('status', 'active'),
                type: 'success',
            },
            {
                value: 'inactive',
                name: 'inactive',
                text: enumLabel('status', 'inactive'),
                type: 'danger',
            },
        ],
        {},
        {
            placeholder: placeholder('status'),
        },
    ),
    created_by_name: CreatedByNameField.relationToOne(
        'created_by_name',
        field('created_by_name'),
        {
            placeholder: placeholder('choose'),
            filter_label: label('created_by'),
            primary: false,
            dynamic: true,
        },
    ),
    created_by_name_string: new StringField(
        'created_by_name',
        field('created_by_name'),
    ),
};

export class AccountModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
