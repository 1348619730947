<template>
    <st-modal ref='modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="md"
            :title="title"
            :hideHeaderClose="isLoading"
            customClass="form-modal"
    >
        <template #body>
            <account-form
                :roles="mappedRoles"
                @submitForm="onSubmit"
                @cancelForm="hide"
            >
                <template #buttons="{ submit, cancel }">
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="cancel"
                            :disabled="isLoading"
                        >
                            {{ cancelText }}
                        </st-button>
                        <st-button
                            size="large"
                            :callback="submit"
                            :spinner="isLoading"
                        >
                            {{ submitText }}
                        </st-button>
                    </div>
                </template>
            </account-form>
        </template>
    </st-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccountForm from '@/modules/accounts/components/AccountForm.vue';
import { capitalize } from '../../../core/helpers/globalMethods';

export default {
    name: 'AddAccountModal',
    components: {
        AccountForm,
    },
    methods: {
        ...mapActions({
            addAccount: 'accounts/form/addAccount',
        }),
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        async onSubmit(account) {
            await this.addAccount(account);
            this.hide();
            const title = this.$t('ACCOUNTS.NOTIFIERS.INVITE_TITLE');
            const text = this.$t('ACCOUNTS.NOTIFIERS.INVITE_TEXT', { email: account.email.toLowerCase() });
            this.$alert({
                type: 'success',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        },
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            roles: 'accounts/list/roles',
        }),
        mappedRoles() {
            return this.roles.map(role =>
                ({ ...role, display_name: capitalize(role.name) }));
        },
        isLoading() {
            return this.loading['accounts/addAccount'];
        },
        title() {
            return this.$t('ACCOUNTS.MODALS.ADD_NEW_ACCOUNT');
        },
        cancelText() {
            return this.$t('ACCOUNTS.BUTTONS.CANCEL');
        },
        submitText() {
            return this.$t('ACCOUNTS.BUTTONS.INVITE');
        },
    },
}
</script>
