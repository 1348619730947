<template>
    <form novalidate="novalidate" id="st-accounts-form"> 
        <div class="row pb-6">
            <div class="col">
                <div class="form-group">
                    <label>
                        {{ $t('ACCOUNTS.FIELDS.LAST_NAME') }} *
                    </label>
                    <div label="">
                        <st-input-text 
                            v-model="account.last_name"
                            ref="last_name" 
                            name="last_name"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('ACCOUNTS.FIELDS.FIRST_NAME') }} *
                    </label>
                    <div label="">
                        <st-input-text 
                            v-model="account.first_name"
                            ref="first_name" 
                            name="first_name"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('ACCOUNTS.FIELDS.ROLE') }} *
                    </label>
                    <div label="">
                        <b-form-select
                            v-model="account.role_id"
                            :options="roles"
                            value-field="id"
                            text-field="display_name"
                            ref="role"
                            name="role"
                            :disabled="isCurrentUsersAccount"
                        >
                        </b-form-select>
                    </div>
                </div>
                <div v-if="account.status && account.status !== 'pending'" class="form-group">
                    <label>
                        {{ $t('ACCOUNTS.FIELDS.STATUS') }} *
                    </label>
                    <div label="">
                        <b-form-select
                            v-model="account.status"
                            :options="statuses"
                            value-field="value"
                            text-field="text"
                            ref="status"
                            name="status"
                            :disabled="isCurrentUsersAccount"
                        >
                        </b-form-select>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('ACCOUNTS.FIELDS.EMAIL') }} *
                    </label>
                    <div label="">
                        <st-input-text 
                            v-model="account.email"
                            ref="email" 
                            name="email"
                            :disabled="account.status === 'active'"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('ACCOUNTS.FIELDS.PHONE') }} *
                    </label>
                    <div label="">
                        <st-input-text 
                            v-model="account.phone_number"
                            ref="phone_number" 
                            name="phone_number"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <slot name="buttons" :submit="submit" :cancel="cancel"/>
    </form>

</template>

<script>
import { mapGetters } from 'vuex';

import KTUtil from '@/assets/js/components/util';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';

export default {
    name: 'AccountForm',
    props: {
        selectedAccount: {
            type: Object,
            default() {
                return {
                    last_name: '',
                    first_name: '',
                    role_id: '',
                    email: '',
                    phone_number: '',
                };
            },
        },
        roles: {
            type: Array,
        },
    },
    data() {
        return {
            account: { ...this.selectedAccount },
        };
    },
    computed: {
        ...mapGetters({
            statuses: 'shared/statuses',
            currentUser: 'auth/currentUser',
        }),
        isCurrentUsersAccount() {
            return this.currentUser.account_id === this.account.account_id;
        },
    },
    methods: {
        async submit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                this.$emit('submitForm', this.account);
            }
        },
        cancel() {
            this.$emit('cancelForm');
        },
    },
    mounted() {
        const form = KTUtil.getById('st-accounts-form');

        const notEmpty = {
            message: this.$t('VALIDATION.REQUIRED_FIELD'),
        };

        this.fv = formValidation(form, {
            fields: {
                first_name: {
                    validators: {
                        notEmpty,
                    },
                },
                last_name: {
                    validators: {
                        notEmpty,
                    },
                },
                role: {
                    validators: {
                        notEmpty,
                    },
                },
                email: {
                    validators: {
                        email: {
                            message: this.$t('VALIDATION.EMAIL_FIELD'),
                        },
                    },
                },
                phone_number: {
                    validators: {
                        phoneNumber: {
                            message: this.$t('VALIDATION.PHONE_FIELD'),
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        });
    },
}
</script>