<template>
    <st-modal ref='modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="md"
            :title="title"
            :hideHeaderClose="isLoading"
            customClass="form-modal"
    >
        <template #body>
            <account-form
                :selectedAccount="mappedAccount"
                :roles="mappedRoles"
                @submitForm="onSubmit"
                @cancelForm="onResendLink"
            >
                <template #buttons="{ submit, cancel }">
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="cancel"
                            :spinner="isLoadingResendLink"
                            :disabled="resendLinkDisabled"
                        >
                            {{ resendText }}
                        </st-button>

                        <st-button
                            size="large"
                            :callback="submit"
                            :spinner="isLoadingUpdateAccount"
                        >
                            {{ submitText }}
                        </st-button>
                    </div>
                </template>
            </account-form>
        </template>
    </st-modal>
</template>

<script>
import AccountForm from '@/modules/accounts/components/AccountForm.vue';
import { mapActions, mapGetters } from 'vuex';
import Message from '@/shared/message/message';

export default {
    name: 'EditAccountModal',
    components: {
        AccountForm
    },
    props: {
        selectedAccount: {
            type: Object,
        },
    },
    methods: {
        ...mapActions({
           updateAccount: 'accounts/form/updateAccount',
           resendLink: 'accounts/list/resendLink',
        }),
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        async onSubmit(account) {
            await this.updateAccount(account);
            this.hide();
            Message.success('ACCOUNTS.NOTIFIERS.EDITED_SUCCESS');
        },
        async onResendLink() {
            await this.resendLink(this.selectedAccount);
            this.hide();
            Message.success('ACCOUNTS.NOTIFIERS.RESEND_SUCCESS');
        },
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            roles: 'accounts/list/roles',
        }),
        mappedRoles() {
            return this.roles.map(role =>
                ({ ...role, display_name: role.name.charAt(0).toUpperCase() + role.name.slice(1) }));
        },
        mappedAccount() {
            const role = this.roles.find(r => r.name === this.selectedAccount.roles[0]);
            return {
                ...this.selectedAccount,
                email: this.selectedAccount.user_email,
                role_id: role.id,
            }
        },
        isLoadingUpdateAccount() {
            return this.loading['accounts/updateAccount'];
        },
        isLoadingResendLink() {
            return this.loading['accounts/resendLink'];
        },
        isLoading() {
            return this.isLoadingUpdateAccount || this.isLoadingResendLink;
        },
        title() {
            return this.$t('ACCOUNTS.MODALS.EDIT_ACCOUNT');
        },
        resendText() {
            return this.$t('ACCOUNTS.BUTTONS.RESEND_LINK');
        },
        submitText() {
            return this.$t('ACCOUNTS.BUTTONS.SAVE');
        },
        resendLinkDisabled() {
            return this.isLoadingUpdateAccount || this.selectedAccount.status !== 'pending';
        },
    },
}
</script>
