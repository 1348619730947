<template>
    <div>
        <Loader v-if="isLoading"/>
        <st-page
            :title="$t('ACCOUNTS.TITLE')"
        >
            <template #toolbar>
                <accounts-toolbar/>
            </template>
            <accounts-filter>
                <accounts-table />
            </accounts-filter>
        </st-page>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AccountsToolbar from '@/modules/accounts/components/AccountsToolbar.vue';
import AccountsFilter from '../components/AccountsFilter.vue';
import AccountsTable from '@/modules/accounts/components/AccountsTable.vue';

const accountsModule = () => import('@/modules/accounts/accounts-store');

export default {
    components: {
        AccountsToolbar,
        AccountsFilter,
        AccountsTable,
    },
    created() {
        this.getRoles();
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        loadingAccounts() {
            return this.loading['accounts/getAccounts'];
        },
        loadingSelectAccount() {
            return this.loading['accounts/selectAccount'];
        },
        isLoading() {
            return this.loadingAccounts || this.loadingSelectAccount;
        },
    },
    methods: {
        ...mapActions({
            getRoles: 'accounts/list/getRoles',
        }),
    },
}
</script>
