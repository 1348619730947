<template>
    <st-filters-pagination
        :header="$t('ACCOUNTS.LIST.HEADER')"
        :perPage="10"
        :total="total"
        stateModule="accounts/list"
        :filters="filters"
        @change="doFilter"
        ref="filters"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { AccountModel } from '@/modules/accounts/account-model';

const { fields } = AccountModel;

const filterSchema = new FilterSchema([
    fields.role,
    fields.status,
    fields.user_name,
    fields.created_by_name,
]);

export default {
    name: 'AccountsFilter',
    data() {
        return {
            filters: filterSchema.fields,
        }
    },
    computed: {
        ...mapGetters({
            total: 'accounts/list/total',
        }),
    },
    methods: {
        ...mapActions({
            getAccounts: 'accounts/list/getAccounts',
        }),
        ...mapMutations({
            setParams: 'accounts/list/setParams',
        }),
        doFilter(params) {
            this.setParams(params);
            this.getAccounts();
        },
        updateOptions() {
            this.$refs['filters'].updateOptions();
        },
    },
}
</script>
