import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export default class CreatedByNameField {

    static relationToOne(name, label, filterParams) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ApiService.query('team-users', {
                        params: {
                            group: 'created_by_name',
                        },
                    })
                        .then(({ data }) => {
                            resolve(data.data);
                        });
                });
            },
            (record) => {
                return {
                    value: record.created_by_name,
                    text: record.created_by_name,
                };
            },
            {},
            filterParams,
        );
    }
};
